@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

$family-sans-serif: "Nunito", sans-serif;
$body-background-color: #111;
$table-background-color: transparent;

$text: #DDD;
$table-color: #FFF;
$border: #333;
$text-strong: #CCC;
$scheme-main: #222;
$border-hover: #666;

$section-padding: 1rem 1.5rem;

$dimensions: 50 296;

$navbar-background-color: transparent;
$navbar-height: 2rem;
$navbar-item-color: #CCC;
$navbar-item-hover-background-color: transparent;

@import "bulma/sass/utilities/_all";
@import "bulma/sass/base/_all";

//@import "bulma/sass/elements/_all";
    //@import "bulma/sass/elements/box.sass";
    @import "bulma/sass/elements/button.sass";
    @import "bulma/sass/elements/container.sass";
    @import "bulma/sass/elements/content.sass";
    @import "bulma/sass/elements/icon.sass";
    @import "bulma/sass/elements/image.sass";
    @import "bulma/sass/elements/notification.sass";
    //@import "bulma/sass/elements/progress.sass";
    @import "bulma/sass/elements/table.sass";
    //@import "bulma/sass/elements/tag.sass";
    @import "bulma/sass/elements/title.sass";
    @import "bulma/sass/elements/other.sass";

//@import "bulma/sass/form/_all";
    @import "bulma/sass/form/shared.sass";
    @import "bulma/sass/form/input-textarea.sass";
    //@import "bulma/sass/form/checkbox-radio.sass";
    //@import "bulma/sass/form/select.sass";
    //@import "bulma/sass/form/file.sass";
    @import "bulma/sass/form/tools.sass";

//@import "bulma/sass/components/_all";
    //@import "bulma/sass/components/breadcrumb.sass";
    //@import "bulma/sass/components/card.sass";
    //@import "bulma/sass/components/dropdown.sass";
    @import "bulma/sass/components/level.sass";
    //@import "bulma/sass/components/media.sass";
    //@import "bulma/sass/components/menu.sass";
    //@import "bulma/sass/components/message.sass";
    //@import "bulma/sass/components/modal.sass";
    @import "bulma/sass/components/navbar.sass";
    //@import "bulma/sass/components/pagination.sass";
    //@import "bulma/sass/components/panel.sass";
    //@import "bulma/sass/components/tabs.sass";

//@import "bulma/sass/grid/_all";
    @import "bulma/sass/grid/columns.sass";
    //@import "bulma/sass/grid/tiles.sass";

@import "bulma/sass/helpers/_all";

//@import "bulma/sass/layout/_all";
    @import "bulma/sass/layout/hero.sass";
    @import "bulma/sass/layout/section.sass";
    //@import "bulma/sass/layout/footer.sass";

// Player css

@import "bulma-slider/index.sass";

.player {
    border-top: 1px solid $border;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $body-background-color;
}

.album {
    display: inline-flex;
    width: 296px;
    margin: 0 2.5em 1.5em 0;
    figure {
        margin-bottom: 0.5em;
    }
}

.tracklist {
    display: inline-flex;
    min-width: 500px;
    flex: auto;
    & tr:hover {
        background-color: #171717;
    }
}

// navbar burger

#nav-toggle-state {
    display: none;
}
#nav-toggle-state:checked ~ .navbar-menu {
    display: block;
}
